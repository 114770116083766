export enum ERoutes {
  HOME = "home",
  USERS = "users",
  CUSTOMERS = "customers",
  ROLES = "roles",
  LOGIN = "login",
  CHANGE_PASSWORD = "change-password",
  REGISTER = "registration",
  SETTINGS = "settings",
  TRANSLATIONS = "translations",
  NOTIFICATIONS = "notifications",
  NOTIFICATION_TEMPLATES = "notification-templates",
  CATEGORIES = "categories",
  SHOPS = "shops",
  SUBSCRIPTION_PLANS = "subscription-plans",
  PROFILE = "profile",
  RESET_PASSWORD = "reset-password",
  AUDIO_RECORD = "audio-record",
}
