export enum EMultipleConfigType {
  UNLIMITED = 1,
  EXACT,
  RANGE,
}

export enum ECommentConfigType {
  UNLIMITED = 1,
  RANGE,
}

export enum EDistributionMethods {
  Default = 0,
  SMS = 1,
  Push = 2,
  Email = 3,
}
