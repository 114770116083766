import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import useTranslation from "shared/helpers/hooks/useTranslation";
import { requiredRules, passwordRules } from "shared/helpers/validators";
import ButtonLoader from "shared/ui/ButtonLoader";
import TextInput from "shared/ui/TextInput";
import { ERequestStatus } from "store/enums/index.enum";
import { ChangePassword } from "store/slicers/users";

interface IFormData {
  password: string;
  newPassword: string;
}

const ChangePasswordForm = ({ onClose }: { onClose: () => void }) => {
  const t = useTranslation();
  const dispatch = useAsyncDispatch();
  const methods = useForm<IFormData>();

  const onSubmit = async (formData: IFormData) => {
    const { meta } = await dispatch(ChangePassword(formData));

    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      return;
    }

    toast.success(t("password_change_success"));
    onClose?.();
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <Card>
          <CardContent sx={{ pt: 4 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextInput<IFormData>
                    label="Password"
                    name="password"
                    isSecure
                    rules={requiredRules}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextInput<IFormData>
                    label="New Password"
                    name="newPassword"
                    isSecure
                    rules={{ ...requiredRules, ...passwordRules }}
                  />
                </Grid>
              </Grid>
              <Box mt={4}>
                <ButtonLoader
                  fullWidth
                  onClick={methods.handleSubmit(onSubmit)}
                  isLoading={false}
                  type="submit"
                >
                  <Typography>{"Save"}</Typography>
                </ButtonLoader>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </FormProvider>
    </Box>
  );
};

export default ChangePasswordForm;
