import {
  IAddEditTriggerServices,
  ITriggerServices,
} from "./../interfaces/triggerServices";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EBaseUrl } from "index";
import thunkOptions from "store/config/thunkOptions";
import { api } from "store/services/apiService";

import { ITriggerServicesState } from "store/interfaces/triggerServices";
import { IState } from "store/interfaces/main";

const name = "TRIGGER_SERVICES";

const initialState: ITriggerServicesState = { listData: [] };

export const GetTriggerServices = createAsyncThunk<ITriggerServices[]>(
  `${name}/GetTriggerServices`,
  async () => {
    return (await api.get(`${EBaseUrl.API}/ServiceType/All`)).data;
  },
  thunkOptions
);

export const AddTriggerService = createAsyncThunk<
  ITriggerServices,
  IAddEditTriggerServices
>(
  `${name}/AddTriggerService`,
  async (formData) => {
    return await api.post(`${EBaseUrl.API}/ServiceType/Create`, formData);
  },
  thunkOptions
);

export const UpdateTriggerService = createAsyncThunk<
  ITriggerServices,
  IAddEditTriggerServices
>(
  `${name}/UpdateTriggerService`,
  async (formData) => {
    return await api.put(
      `${EBaseUrl.API}/ServiceType/Update/${formData?.id}`,
      formData
    );
  },
  thunkOptions
);

export const DeleteTriggerService = createAsyncThunk<ITriggerServices, number>(
  `${name}/DeleteTriggerService`,
  async (id) => {
    return await api.delete(`${EBaseUrl.API}/ServiceType/${id}`);
  },
  thunkOptions
);

const triggerServiceSlice = createSlice({
  initialState,
  name,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetTriggerServices.fulfilled, (state, { payload }) => {
      state.listData = payload;
    });
  },
});

export const selectTriggerServiceData = (state: IState) =>
  state.triggerService.listData;

export default triggerServiceSlice.reducer;
