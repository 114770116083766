import { format } from "date-fns";

export const debounce = (func: (...args: any) => void, timeout = 600) => {
  let timer: NodeJS.Timeout;
  return (...args: []) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const hasUserPermission = (arr: string[] | undefined, perm: string) => {
  return arr?.includes(perm);
};

export const hasOtherPermissionButGet = (
  arr: string[] | undefined,
  permSection: string
) => {
  const sectionArrLength: number | undefined = arr?.filter(
    (i) => i.includes(permSection) && !i.includes("get")
  )?.length;
  return !!sectionArrLength;
};

export const formatDateString = (date: string) => {
  const splittedDate = date.split(" ");
  const newDate = splittedDate[0]
    .split("/")
    .reverse()
    .join("/")
    .concat(` ${splittedDate[1]}`);
  return newDate;
};

export const handleDownload = (payload, dates = [], extension = "xlsx") => {
  const url = window.URL.createObjectURL(new Blob([payload]));

  const link = document.createElement("a");

  const name = dates?.length
    ? `${format(new Date(dates[0]), "dd/MM/yyyy-hh:mm:ss")}-${format(
        new Date(dates[1]),
        "dd/MM/yyyy-hh:mm:ss"
      )}`
    : `${format(new Date(), "dd/MM/yyyy-hh:mm:ss")}`;
  link.href = url;
  link.setAttribute("download", `Satisfai_${name}.${extension}`);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link?.click();
  link?.parentNode?.removeChild(link);
};
