import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { requiredRules } from "shared/helpers/validators";
import ButtonLoader from "shared/ui/ButtonLoader";
import BasicSelect from "shared/ui/Select";
import { IViewUserRoleItem } from "store/interfaces/users";
import { selectUserViewRolesList, selectUsers } from "store/slicers/users";

interface IFormData {
  roleID: number;
}

const SelectActivationRole = ({
  onAction,
  ids,
}: {
  onAction: (roleId: number) => void;
  ids: number[];
}) => {
  const methods = useForm<IFormData>();
  const users = useSelector(selectUsers);
  const userRolesList = useSelector(selectUserViewRolesList);

  const isSingle = useMemo(() => {
    return ids?.length === 1;
  }, [ids.length]);

  const actualUser = useMemo(() => {
    return users?.displayData.find((i) => i.id === ids[0]);
  }, [ids, users?.displayData]);

  const onSubmit = (formData: IFormData) => {
    onAction?.(formData.roleID);
  };

  useEffect(() => {
    if (actualUser && isSingle) {
      methods.reset({
        roleID: userRolesList.find((i) => i.label === actualUser.role).id,
      });
    }
  }, [actualUser, isSingle, methods, userRolesList]);

  return (
    <Box>
      <FormProvider {...methods}>
        <Card>
          <CardContent sx={{ pt: 4 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <BasicSelect<IViewUserRoleItem>
                    name="roleID"
                    label="Role"
                    valueProp="id"
                    labelProp="label"
                    defaultValue=""
                    options={userRolesList}
                    rules={requiredRules}
                  />
                </Grid>
              </Grid>
              <Box mt={4}>
                <ButtonLoader
                  fullWidth
                  onClick={methods.handleSubmit(onSubmit)}
                  isLoading={false}
                  type="submit"
                >
                  <Typography>{"Save"}</Typography>
                </ButtonLoader>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </FormProvider>
    </Box>
  );
};

export default SelectActivationRole;
