import BasicTabs from "shared/ui/Tabs";
import { smsDistributionTabData } from "./constants";
import { Box } from "@mui/system";

const Settings = () => {
  return (
    <Box>
      <BasicTabs tabsData={smsDistributionTabData} />
    </Box>
  );
};

export default Settings;
