import { Typography } from "@mui/material";
import { IColumn } from "shared/ui/Table/constants";
import { ITriggerSource } from "store/interfaces/triggerSource";

export const columns: IColumn[] = [
  {
    label: "ID",
    field: "id",
  },
  {
    label: "name",
    field: "name",
  },
  {
    label: "description",
    field: "description",
  },
  {
    label: "service_types",
    layout: (row: ITriggerSource) => {
      return <Typography>{row.serviceTypeIds?.length}</Typography>;
    },
  },
];
