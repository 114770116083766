import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import { ECampaignSurveyType } from "pages/dashboard/CampaignDetails/questions/LeftSidebar/constants";
import { Controller, useFormContext } from "react-hook-form";
import { requiredRules } from "shared/helpers/validators";
import { LStorage } from "store/config/constants";

const SingleChoiceQuestion = ({ questionData }) => {
  const methods = useFormContext();

  return (
    <Box>
      <Controller
        name={"singleChoice"}
        defaultValue={""}
        control={methods.control}
        rules={requiredRules}
        render={({ field }) => (
          <FormControl>
            <RadioGroup
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value);
                if (
                  questionData?.details?.type ===
                  Number(ECampaignSurveyType.ChooseLang)
                ) {
                  localStorage.setItem(
                    LStorage.PREFERRED_LANGUAGE,
                    e.target.value.toString()
                  );
                }
              }}
              defaultValue=""
              name="radio-buttons-group"
            >
              {questionData?.details?.answers.map((answer) => (
                <FormControlLabel
                  key={answer.id}
                  sx={{
                    alignItems: "flex-start",
                    "& .MuiFormControlLabel-label": {
                      marginTop: "7px",
                    },
                  }}
                  value={answer.id}
                  control={<Radio />}
                  label={answer.value}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default SingleChoiceQuestion;
