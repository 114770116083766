import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Controller, useFormContext } from "react-hook-form";
import useTranslation from "shared/helpers/hooks/useTranslation";

const BasicCheck = ({ name, label }: { name: string; label: string }) => {
  const { control } = useFormContext();
  const t = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      render={({ field: { value, ...field } }) => (
        <FormControlLabel
          control={<Checkbox {...field} checked={!!value} />}
          label={t(label)}
        />
      )}
    />
  );
};

export default BasicCheck;
