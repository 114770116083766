import BasicTabs from "shared/ui/Tabs";
import { Box } from "@mui/system";
import TriggerServices from "../TriggerServices";
import TriggerSourcePage from "../TriggerSources";
import { useMemo } from "react";
import usePermission from "shared/helpers/hooks/usePermission";
import {
  EServiceTypesPermissions,
  ETriggerPermissions,
} from "resources/permissions/permissions.enum";

enum ETriggerTab {
  Service = 0,
  Source,
}

const Trigger = () => {
  const hasReadTriggersPermission = usePermission(ETriggerPermissions.Read);
  const hasReadServiceTypePermission = usePermission(
    EServiceTypesPermissions.Read
  );

  const triggerTabData = useMemo(() => {
    return [
      ...(hasReadTriggersPermission
        ? [
            {
              index: ETriggerTab.Service,
              label: "Service type",
              children: <TriggerServices />,
            },
          ]
        : []),
      ...(hasReadServiceTypePermission
        ? [
            {
              index: ETriggerTab.Source,
              label: "Triggers",
              children: <TriggerSourcePage />,
            },
          ]
        : []),
    ];
  }, [hasReadServiceTypePermission, hasReadTriggersPermission]);

  return (
    <Box>
      <BasicTabs tabsData={triggerTabData} />
    </Box>
  );
};

export default Trigger;
