import { IColumn } from "shared/ui/Table/constants";
import Questions from "./SmsSurvey/componnets/Questions";
import Settings from "./SmsSurvey/componnets/settings";

export const columns: IColumn[] = [
  {
    label: "survey",
    field: "name",
  },
  {
    label: "created",
    field: "creationDate",
  },
  {
    label: "overview_sent",
    field: "sent",
  },
  {
    label: "overview_delivered",
    field: "delivered",
  },
  {
    label: "overview_opened",
    field: "opened",
  },
  {
    label: "overview_responded",
    field: "responded",
  },
  {
    label: "overview_bounced",
    field: "bounced",
  },
];

export const historyColumns: IColumn[] = [
  {
    field: "creationDate",
    label: "created",
  },
  {
    field: "state",
    label: "state",
  },
];

export const deleteCampaignWarningConfig = {
  title: "warning",
  description: "delete_survey",
};

export enum ECreateSurveyTypes {
  Default = 1,
  Sms,
}

export const surveyTypes = [
  {
    id: ECreateSurveyTypes.Default,
    label: "Default",
  },
  {
    id: ECreateSurveyTypes.Sms,
    label: "2-Way SMS",
  },
];

export enum ESmsSurveyTabs {
  QUESTIONS = 0,
  SETTINGS = 1,
}

export const smsSurveyDetailsTabList = [
  {
    index: ESmsSurveyTabs.QUESTIONS,
    label: "Questions",
    children: <Questions />,
  },
  {
    index: ESmsSurveyTabs.SETTINGS,
    label: "Settings",
    children: <Settings />,
  },
];
