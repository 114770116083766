import { FormHelperText } from "@mui/material";
import { Box } from "@mui/system";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
// import { DATE_FORMAT, HOUR_FORMAT } from "store/config/constants";

export interface IDateTimePickerProps {
  name: string;
  label: string;
  defaultValue: Date;
  sx?: any;
  rules?: any;
  disablePast?: boolean;
}

const BasicTimePicker = ({
  name,
  label,
  defaultValue,
  rules,
  sx,
  disablePast = true,
}: IDateTimePickerProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorInfo = useCallback(() => {
    if (name.includes(".")) {
      const [start, end] = name.split(".");
      return errors?.[start]?.[end];
    } else {
      return errors?.name;
    }
  }, [errors, name]);

  return (
    <Box>
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <MobileTimePicker
              {...field}
              sx={sx}
              label={label}
              ampm={false}
              disablePast={disablePast}
              // inputFormat={`${DATE_FORMAT} ${HOUR_FORMAT}`}
            />
          );
        }}
      />
      {!!errorInfo() ? (
        <FormHelperText error>
          {errorInfo()?.message?.toString()}
        </FormHelperText>
      ) : (
        ""
      )}
    </Box>
  );
};

export default BasicTimePicker;
