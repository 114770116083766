import BasicTabs from "shared/ui/Tabs";
import { smsSurveyDetailsTabList } from "../constants";
import { Box } from "@mui/material";

const SmsSurvey = () => {
  return (
    <Box>
      <BasicTabs tabsData={smsSurveyDetailsTabList} />
    </Box>
  );
};

export default SmsSurvey;
