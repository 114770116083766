import { Grid, Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import { emailRule, requiredRules } from "shared/helpers/validators";
import ButtonLoader from "shared/ui/ButtonLoader";
import BasicCheck from "shared/ui/Checkbox";
import BasicSelect from "shared/ui/Select";
import TextInput from "shared/ui/TextInput";
import { ERequestStatus } from "store/enums/index.enum";
import { IViewUserRoleItem } from "store/interfaces/users";
import { CreateUser, selectUserViewRolesList } from "store/slicers/users";

interface IFormData {
  name: string;
  surname: string;
  email: string;
  createPassword: boolean;
  roleID: number;
  department: string;
  departmentName: string;
  personalNumber: string;
  parentPersonalNumber: string;
  title: string;
  titleName: string;
  phone: string;
}

enum ERoles {
  SuperAdmin = 1,
}

const AddUserForm = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAsyncDispatch();
  const userRolesList = useSelector(selectUserViewRolesList);

  const methods = useForm<IFormData>();

  const onSubmit = async (formData: IFormData) => {
    const { meta } = await dispatch(CreateUser(formData));
    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      return;
    }

    toast.success("user_created_success");
    onClose?.();
  };

  const isOptionDisabled = useCallback((option) => {
    return option.id === ERoles.SuperAdmin;
  }, []);

  return (
    <Box>
      <FormProvider {...methods}>
        <Grid item xs={12} md={6} lg={8}>
          <Card>
            <CardContent sx={{ pt: 4 }}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextInput<IFormData>
                      label="name"
                      rules={requiredRules}
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<IFormData>
                      label="surname"
                      rules={requiredRules}
                      name="surname"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<IFormData>
                      label="phone"
                      type="number"
                      placeholder="37400000000"
                      name="phone"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<IFormData>
                      label="email"
                      name="email"
                      rules={{ ...requiredRules, ...emailRule }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BasicSelect<IViewUserRoleItem>
                      name="roleID"
                      label="Role"
                      valueProp="id"
                      labelProp="label"
                      defaultValue=""
                      isOptionDisabled={isOptionDisabled}
                      options={userRolesList}
                      hasDisabledOption
                      rules={requiredRules}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<IFormData>
                      label="departmentCode"
                      name="department"
                      rules={requiredRules}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<IFormData>
                      label="departmentName"
                      name="departmentName"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<IFormData>
                      label="personalNumber"
                      rules={{ ...requiredRules }}
                      name="personalNumber"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<IFormData>
                      label="parentPersonalNumber"
                      name="parentPersonalNumber"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextInput<IFormData>
                      label="title"
                      rules={{ ...requiredRules }}
                      name="title"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput<IFormData> label="titleName" name="titleName" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BasicCheck
                      label={"createPassword"}
                      name={"createPassword"}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={4}>
                <ButtonLoader
                  fullWidth
                  onClick={methods.handleSubmit(onSubmit)}
                  isLoading={false}
                  type="submit"
                >
                  <Typography>{"Save"}</Typography>
                </ButtonLoader>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </FormProvider>
    </Box>
  );
};

export default AddUserForm;
