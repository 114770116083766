import { Box, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import MicRecorder from "mic-recorder-to-mp3";
import { useEffect, useRef, useState } from "react";
import ButtonLoader from "shared/ui/ButtonLoader";
import StopIcon from "@mui/icons-material/Stop";
import BackupIcon from "@mui/icons-material/Backup";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import { GetAudioAnalyzeResponse } from "store/slicers/common";
import { ERequestStatus } from "store/enums/index.enum";
import { KeyboardVoice, UploadFileOutlined } from "@mui/icons-material";
import format from "date-fns/format";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const AudioProcessing = () => {
  const dispatch = useAsyncDispatch();
  const [isRecording, setRecording] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const [isBlocked, setBlocked] = useState(false);
  const [processingResult, setProcessingResult] = useState(null);

  const uploadFile = useRef();
  const [isLoading, setLoading] = useState(false);
  const [Mp3Recorder] = useState(new MicRecorder({ bitRate: 128 }));

  const start = async () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      try {
        await Mp3Recorder.start();
        setRecording(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const stop = async () => {
    try {
      await Mp3Recorder.stop();
      const mp3 = await Mp3Recorder.getMp3();
      const [buffer] = mp3;
      const new_file = new File(
        buffer,
        `new_recording_${format(new Date(), "dd_MM_yyyy_hh_mm_ss")}.mp3`,
        { type: "audio/mpeg" }
      );
      setRecording(false);
      setNewFile(new_file);
    } catch (e) {
      console.log(e);
    }
  };

  const handleUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files?.[0];
      if (file) {
        setNewFile(file);
      }
    }
  };

  const onPressFileUpload = () => {
    uploadFile?.current?.click();
  };

  console.log(processingResult);
  const sendToBackend = async (audioFile) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("audio_file", audioFile);
    formData.append("language", "Armenian");
    formData.append("company", "PostOffice");
    const { meta, payload } = await dispatch(GetAudioAnalyzeResponse(formData));
    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      setLoading(false);
      return;
    }
    setProcessingResult(payload);
    setLoading(false);
  };

  useEffect(() => {
    navigator?.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        setBlocked(false);
      },
      () => {
        console.log("Permission Denied");
        setBlocked(true);
      }
    );
  }, []);

  return (
    <Box>
      <Box
        p={4}
        sx={{
          minHeight: "calc(100vh - 220px)",
          maxHeight: "calc(100vh - 220px)",
          overflow: "auto",
        }}
      >
        {processingResult?.conversationAnalysisSummary ? (
          <Box
            sx={{ marginBottom: 2 }}
            display="flex"
            flexDirection={"column"}
            gap={2}
          >
            <Card>
              <CardContent>
                <Typography sx={{ fontWeight: "bold" }}>Analysis</Typography>
                <Typography>
                  {" "}
                  {processingResult?.conversationAnalysisSummary}
                </Typography>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Typography sx={{ fontWeight: "bold" }}>
                  Transcription
                </Typography>
                <Typography> {processingResult?.transcription}</Typography>
              </CardContent>
            </Card>

            <Grid container spacing={3}>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Satisfaction Score
                    </Typography>
                    <Typography>
                      {" "}
                      {processingResult?.clientSatisfactionScore}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Typography sx={{ fontWeight: "bold" }}>Emotion</Typography>
                    <Typography>
                      {" "}
                      {processingResult?.emotionAnalysis?.predominantEmotion}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Typography sx={{ fontWeight: "bold" }}>Urgency</Typography>
                    <Typography> {processingResult?.taskUrgency}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Sentiment Score
                    </Typography>
                    <Typography>
                      {" "}
                      {processingResult?.sentimentAnalysis?.overallScore}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Department
                    </Typography>
                    <Typography>
                      {" "}
                      {processingResult?.relevantDepartment}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardContent>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Processing
                    </Typography>
                    <Typography>
                      Duration - {processingResult?.processing_duration}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                      Emotions Timeline
                    </Typography>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "auto auto auto",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>Start</Typography>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Middle
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }}>End</Typography>
                      <Typography>
                        {
                          processingResult?.emotionAnalysis?.emotionTimeline
                            ?.start
                        }
                      </Typography>
                      <Typography>
                        {
                          processingResult?.emotionAnalysis?.emotionTimeline
                            ?.middle
                        }
                      </Typography>
                      <Typography>
                        {
                          processingResult?.emotionAnalysis?.emotionTimeline
                            ?.end
                        }
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                      Keywords Detected
                    </Typography>
                    {Object.values(
                      processingResult?.semanticAnalysis?.entityRecognition
                    ).map((item: string) => {
                      return <Chip sx={{ m: 1 }} key={item} label={item} />;
                    })}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Typography sx={{ fontSize: 18 }}>
            {"Please record your audio and get the processing results"}
          </Typography>
        )}
      </Box>
      <Box height={80} sx={{ visibility: !newFile ? "hidden" : "visible" }}>
        {newFile && (
          <AudioPlayer
            autoPlay={false}
            autoPlayAfterSrcChange={false}
            src={URL.createObjectURL(newFile)}
          />
        )}
      </Box>
      <Box p={2} sx={{ display: "flex", background: "white" }}>
        <Box sx={{ display: "flex", gap: 3 }}>
          <ButtonLoader
            onClick={onPressFileUpload}
            disabled={false}
            isLoading={false}
            startIcon={<UploadFileOutlined />}
          >
            <div>
              <input
                type="file"
                ref={uploadFile}
                id="upload"
                accept=".mp3"
                style={{ display: "none" }}
                onChange={handleUploadFile}
              />

              <Typography>Upload</Typography>
            </div>
          </ButtonLoader>
          <ButtonLoader
            onClick={start}
            isLoading={isRecording}
            startIcon={<KeyboardVoice />}
          >
            <Typography>{isRecording ? "Recording" : "Record"}</Typography>
          </ButtonLoader>
          <ButtonLoader
            color="error"
            onClick={stop}
            disabled={!isRecording}
            isLoading={false}
            startIcon={<StopIcon />}
          >
            <Typography>Stop</Typography>
          </ButtonLoader>
        </Box>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "row-reverse" }}>
          <ButtonLoader
            onClick={() => sendToBackend(newFile)}
            disabled={!newFile}
            isLoading={isLoading}
            startIcon={<BackupIcon />}
          >
            <Typography>Submit</Typography>
          </ButtonLoader>
        </Box>
      </Box>
    </Box>
  );
};

export default AudioProcessing;
