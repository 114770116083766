import {
  Button,
  Divider,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import BasicAutocomplete from "shared/ui/Autocomplete";
import TextInput from "shared/ui/TextInput";
import LinkIcon from "@heroicons/react/24/outline/LinkIcon";
import EnvelopeIcon from "@heroicons/react/24/outline/EnvelopeIcon";
import {
  DistributionSchedule,
  GetCampaignById,
  selectCampaignInfo,
} from "store/slicers/campaignDetail";
import RightDrawer from "shared/ui/Drawer";
import { Fragment, useEffect, useState } from "react";
import TestSMSForm from "./TestSMSForm";
import ButtonLoader from "shared/ui/ButtonLoader";
import { requiredRules } from "shared/helpers/validators";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import toast from "react-hot-toast";
import { ERequestStatus } from "store/enums/index.enum";
import { useParams } from "react-router-dom";
import useTranslation from "shared/helpers/hooks/useTranslation";
import {
  selectButtonLoadingState,
  setButtonLoading,
} from "store/slicers/common";
import {
  GetTriggerSources,
  selectTriggerSourceData,
} from "store/slicers/triggerSource";
import { EDistributionMethods } from "store/enums/campaignDetails";
import InputField from "shared/ui/TextInput";
import BasicTimePicker from "shared/ui/TimePicker";
import { addHours } from "date-fns";
import BasicSelect from "shared/ui/Select";
import { Info } from "@mui/icons-material";

const defaultValues = {
  ignoreQuarantine: false,
  message: "",
  numberOfTransaction: "",
  postpone: false,
  postponeTime: "",
  quarantinePeriod: "",
  surveyQuarantine: false,
  groupQuarantine: false,
  triggerIDs: [],
  name: "",
  expiation: {
    days: "",
    hours: "",
    minutes: "",
  },
  activeHours: { from: new Date(), to: addHours(new Date(), 1) },
};

const SmsDistributionForm = () => {
  const t = useTranslation();
  const { id } = useParams();
  const campaignInfo = useSelector(selectCampaignInfo);
  const dispatch = useAsyncDispatch();
  const isButtonLoading = useSelector(selectButtonLoadingState);
  const [testSmsOpen, setTestSmsOpen] = useState(false);
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const { watch } = methods;

  const triggers = useSelector(selectTriggerSourceData);

  const handleAddLink = () => {
    methods.setValue("message", methods.watch("message") + " {link}");
  };

  const onSubmit = async (formData) => {
    dispatch(setButtonLoading(true));
    const { triggerIDs, ...rest } = formData;

    const data = {
      ...rest,
      triggerIDs: triggerIDs.map((item) => {
        return item.id;
      }),
      distributionMethod: EDistributionMethods.SMS,
      isLink: true,
    };
    const { meta } = await dispatch(
      DistributionSchedule({ id: Number(id), data })
    );

    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      dispatch(setButtonLoading(false));
      return;
    }

    toast.success("Campaign schedule is set.");
    dispatch(setButtonLoading(false));
  };

  useEffect(() => {
    const distributionInfo = campaignInfo?.campaignTriggers.find(
      (c) => c.distributionMethod === EDistributionMethods.SMS
    );
    const selectedTriggerIds = campaignInfo?.campaignTriggers
      .filter((i) => i.distributionMethod === EDistributionMethods.SMS)
      .map((t) => t.TriggerId);
    if (distributionInfo) {
      methods.reset({
        name: distributionInfo.name,
        ignoreQuarantine: distributionInfo.ignoreQuarantine,
        message: distributionInfo.message,
        numberOfTransaction: distributionInfo?.numberOfTransaction
          ? distributionInfo?.numberOfTransaction?.toString()
          : "",
        postpone: distributionInfo.postpone,
        postponeTime: distributionInfo?.postponeTime
          ? distributionInfo.postponeTime.toString()
          : "",
        quarantinePeriod: distributionInfo?.quarantinePeriod
          ? distributionInfo.quarantinePeriod.toString()
          : "",
        surveyQuarantine: distributionInfo.surveyQuarantine,
        triggerIDs: triggers?.filter((i) => selectedTriggerIds.includes(i.id)),
      });
    }
  }, [campaignInfo, methods, triggers]);

  useEffect(() => {
    Promise.all([
      dispatch(GetTriggerSources()),
      dispatch(GetCampaignById(Number(id))),
    ]);
  }, [dispatch, id]);

  return (
    <Box p={4} m={2} component={Paper}>
      <FormProvider {...methods}>
        <Box display="flex">
          <Box flex={1}>
            <Typography fontSize={18} mb={2}>
              {t("schedule")}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography ml={6} fontSize={18} mb={2}>
              {t("message_details")}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent={"space-between"}>
          <Box flex={1}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"stretch"}
              mb={2}
            >
              <Box flex={1} display="flex" alignItems="center">
                <Tooltip
                  title={
                    "Here you can select the trigger source from where you want to receive the triggers. "
                  }
                >
                  <Typography>{t("trigger_source")}</Typography>
                </Tooltip>
              </Box>
              <Box flex={1}>
                <BasicAutocomplete
                  options={triggers}
                  inputLabel={"Triggers"}
                  name={"triggerIDs"}
                  onChangeCB={() => methods.trigger("triggerIDs")}
                  rules={requiredRules}
                  optionLabel="name"
                  defaultValue={[]}
                  multiple
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"stretch"}
              mb={2}
            >
              <Box flex={1} display="flex" alignItems="center">
                <Tooltip
                  title={
                    "In case activating this function the survey will be sent to the customer after the predefined minutes.  "
                  }
                >
                  <Typography>{t("postpone_survey")}</Typography>
                </Tooltip>
              </Box>
              <Box flex={1}>
                <Controller
                  name="postpone"
                  control={methods.control}
                  render={({ field }) => (
                    <Switch color="success" {...field} checked={field.value} />
                  )}
                />
              </Box>
            </Box>
            {methods.watch("postpone") && (
              <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems={"stretch"}
                mb={2}
              >
                <Box flex={1} display="flex" alignItems="center">
                  <Typography>{t("postpone_survey_for")}</Typography>
                </Box>
                <Box flex={1}>
                  <TextInput
                    rules={requiredRules}
                    label="minutes"
                    name="postponeTime"
                  />
                </Box>
              </Box>
            )}
            <Divider />
          </Box>
          <Box flex={1}>
            <Box ml={6}>
              <TextInput name="message" label="message_text" />
              <Box mt={2}>
                <Box display="flex">
                  <Tooltip title="Click on the button to add the link where you want it to be appeared in the text. ">
                    <Button
                      startIcon={<LinkIcon height={20} />}
                      variant="outlined"
                      disabled={methods.watch("message")?.includes("{link}")}
                      onClick={handleAddLink}
                    >
                      <Typography>{t("add_link")}</Typography>
                    </Button>
                  </Tooltip>
                  <Box ml={2}>
                    <Button
                      startIcon={<EnvelopeIcon height={20} />}
                      variant="outlined"
                      onClick={() => setTestSmsOpen(true)}
                    >
                      <Typography>{t("test_sms")}</Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography mt={2} fontSize={18} mb={2}>
          {t("quarantine")}
        </Typography>
        <Box display="flex" justifyContent={"space-between"}>
          <Box flex={1}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"stretch"}
              mb={2}
            >
              <Box flex={1} display="flex" alignItems="center">
                <Tooltip
                  title={
                    "In case activating this function the customers will be quarantined after the predefined transactions."
                  }
                >
                  <Typography>{t("customer_quarantine")}</Typography>
                </Tooltip>
              </Box>
              <Box flex={1}>
                <Controller
                  name="surveyQuarantine"
                  control={methods.control}
                  render={({ field }) => (
                    <Switch color="success" {...field} checked={field.value} />
                  )}
                />
              </Box>
            </Box>
            {methods.watch("surveyQuarantine") && (
              <Fragment>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"stretch"}
                  mb={2}
                >
                  <Box flex={1} display="flex" alignItems="center">
                    <Typography>{t("quarantine_the_customer")}</Typography>
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      rules={requiredRules}
                      label="transaction"
                      name="numberOfTransaction"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"stretch"}
                  mb={2}
                >
                  <Box flex={1} display="flex" alignItems="center">
                    <Typography>{t("quarantine_the_customer_for")}</Typography>
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      rules={requiredRules}
                      label="days"
                      name="quarantinePeriod"
                    />
                  </Box>
                </Box>
              </Fragment>
            )}
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"stretch"}
              mb={2}
            >
              <Box flex={1} display="flex" alignItems="center">
                <Tooltip
                  title={
                    "In case activation of this function the survey will be sent the quarantined customers as well after receiving the trigger."
                  }
                >
                  <Typography>
                    {t("include_the_quarantined_customers")}
                  </Typography>
                </Tooltip>
              </Box>
              <Box flex={1}>
                <Controller
                  name="ignoreQuarantine"
                  control={methods.control}
                  render={({ field }) => (
                    <Switch color="success" {...field} checked={field.value} />
                  )}
                />
              </Box>
            </Box>
            <Divider />
            <Box sx={{ mt: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }} mb={2} mt={2}>
                <Typography fontSize={18}>
                  Group specific quarantine limits
                </Typography>
                <Tooltip
                  title={"Set unique limits for specific recipient groups"}
                >
                  <Info />
                </Tooltip>
              </Box>
              <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems={"stretch"}
                mb={2}
              >
                <Box flex={1} display="flex" alignItems="center">
                  <Typography>Include group quarantine</Typography>
                </Box>
                <Box flex={1}>
                  <Controller
                    name="groupQuarantine"
                    control={methods.control}
                    render={({ field }) => (
                      <Switch
                        color="success"
                        {...field}
                        checked={field.value}
                      />
                    )}
                  />
                </Box>
              </Box>
              {watch("groupQuarantine") && (
                <>
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems={"stretch"}
                    mb={2}
                  >
                    <Box flex={1} display="flex" alignItems="center">
                      <Typography>Groups</Typography>
                    </Box>
                    <Box flex={1}>
                      <BasicSelect
                        options={[
                          { id: 1, name: "Premium client" },
                          { id: 2, name: "Retail clients" },
                        ]}
                        label={"Groups"}
                        name={"group"}
                        labelProp="name"
                        valueProp="id"
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems={"stretch"}
                    mb={2}
                  >
                    <Box flex={1} display="flex" alignItems="center">
                      <Typography>Survey limit</Typography>
                    </Box>
                    <Box flex={1}>
                      <TextInput label="Max Surveys" name="max_surveys_1" />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems={"stretch"}
                    mb={2}
                  >
                    <Box flex={1} display="flex" alignItems="center">
                      <Typography>Time Period</Typography>
                    </Box>
                    <Box flex={1}>
                      <BasicSelect
                        options={[
                          { id: 1, name: "Daily" },
                          { id: 2, name: "Weekly" },
                          { id: 3, name: "Monthly" },
                        ]}
                        label={"Time Period"}
                        name={"time_period_1"}
                        labelProp="name"
                        valueProp="id"
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>

            <Divider />
            <Box sx={{ mb: 10, mt: 2 }}>
              <Typography fontSize={18} mb={2}>
                Settings
              </Typography>
              <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems={"stretch"}
                mb={2}
              >
                <Box flex={1} display="flex" alignItems="center">
                  <Tooltip
                    title={
                      "This is the time survey will be available after activation."
                    }
                  >
                    <Typography>Expiration time</Typography>
                  </Tooltip>
                </Box>
                <Box flex={1} sx={{ display: "flex", gap: 1 }}>
                  <InputField
                    name="expiation.days"
                    label="days"
                    type="number"
                    rules={{
                      validate: (value) => {
                        return Number(value) >= 0 || "Min value is 0";
                      },
                    }}
                  />
                  <InputField
                    name="expiation.hours"
                    label="hours"
                    type="number"
                    rules={{
                      validate: (value) => {
                        return Number(value) >= 0 || "Min value is 0";
                      },
                    }}
                  />
                  <InputField
                    name="expiation.minutes"
                    label="minutes"
                    type="number"
                    rules={{
                      validate: (value) => {
                        return Number(value) >= 0 || "Min value is 0";
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems={"stretch"}
                mb={2}
              >
                <Box flex={1} display="flex" alignItems="center">
                  <Tooltip
                    title={
                      "This is the time survey will be available through a day."
                    }
                  >
                    <Typography>Active hours</Typography>
                  </Tooltip>
                </Box>
                <Box flex={1} sx={{ display: "flex", gap: 1 }}>
                  <BasicTimePicker
                    name="activeHours.from"
                    label="from"
                    defaultValue={new Date()}
                    disablePast={false}
                    rules={{
                      validate: (value) => {
                        return (
                          value < watch("activeHours.to") ||
                          "From date must be before from date"
                        );
                      },
                    }}
                  />
                  <BasicTimePicker
                    name="activeHours.to"
                    label="to"
                    defaultValue={addHours(new Date(), 1)}
                    disablePast={false}
                    rules={{
                      validate: (value) => {
                        return (
                          value > watch("activeHours.from") ||
                          "To date must be after from date"
                        );
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box flex={1} />
        </Box>
      </FormProvider>
      <Box
        mt={2}
        width="100%"
        p={2}
        sx={{
          zIndex: 1100,
          position: "fixed",
          bottom: 0,
          right: 0,
          border: "1px solid",
          borderColor: "divider",
          backgroundColor: "white",
        }}
        display="flex"
        justifyContent={"flex-end"}
      >
        <Box>
          <ButtonLoader
            fullWidth
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={isButtonLoading}
            disabled={!Object.keys(methods.formState.dirtyFields)?.length}
            type="submit"
          >
            <Typography>{t("save")}</Typography>
          </ButtonLoader>
        </Box>
      </Box>
      <RightDrawer
        open={testSmsOpen}
        setOpen={() => setTestSmsOpen(false)}
        title={"Test SMS"}
      >
        <TestSMSForm onSuccess={() => setTestSmsOpen(false)} />
      </RightDrawer>
    </Box>
  );
};

export default SmsDistributionForm;
