import { Box } from "@mui/system";
import { requiredRules } from "shared/helpers/validators";
import TextInput from "shared/ui/TextInput";

const ContactForm = () => {
  return (
    <Box>
      <Box
        my={2}
        sx={{
          "& textarea": {
            paddingTop: "25px",
          },
        }}
      >
        <TextInput
          name="title"
          placeholder={"Type your text here"}
          label="Title"
          rules={requiredRules}
          multiline
        />
      </Box>
    </Box>
  );
};
export default ContactForm;
