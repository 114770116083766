import {
  Box,
  Card,
  CardContent,
  Theme,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect } from "react";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import BasicRangePicker from "shared/ui/RangePicker";
import {
  GetDashboardData,
  GetSurveyDeliveredData,
  selectDashboardData,
  selectDeliveredData,
} from "store/slicers/dashboard";
import {
  feedbackFilterTypesKeys,
  quickFilterOneTimeTypesFeedback,
} from "../FeedBacks/constants";
import ScoreCharts from "./components/ScoreCharts";
import TrendChart from "./components/TrendChart";
import { ECampaignSurveyType } from "../CampaignDetails/questions/LeftSidebar/constants";
import CompletedCount from "assets/icons/completed_surveys_rate.svg";
import CompletedRate from "assets/icons/completed_surveys_count.svg";
import useTranslation from "shared/helpers/hooks/useTranslation";
import { EDashboardExportMetricTypes } from "./constants";
import { setLoading } from "store/slicers/common";
import StyledToggleButton from "shared/ui/ToggleButton";

interface IFormData {
  range: Array<Dayjs | null>;
  isOneTimeSurvey: string;
}

const Dashboard = () => {
  const lgUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const dashboardData = useSelector(selectDashboardData);
  const deliveredData = useSelector(selectDeliveredData);
  const dispatch = useAsyncDispatch();
  const t = useTranslation();
  const methods = useForm<IFormData>({
    defaultValues: {
      range: [null, null],
      isOneTimeSurvey: "0",
    },
  });

  const { reset } = methods;

  const isOneTimeSurvey = useWatch({
    control: methods.control,
    name: "isOneTimeSurvey",
  });

  const getFilteredDashboardData = useCallback(
    async (dates: Dayjs[]) => {
      if (dates) {
        const data = {
          filters: [
            {
              key: feedbackFilterTypesKeys.DATE,
              queryCondition: 4,
              value: dates?.[0].format("MM/DD/YYYY"),
            },
            {
              key: feedbackFilterTypesKeys.DATE,
              queryCondition: 5,
              value: dates?.[1].format("MM/DD/YYYY"),
            },
            ...(Number(isOneTimeSurvey)
              ? [
                  {
                    key: feedbackFilterTypesKeys.ONE_TIME,
                    queryCondition: isOneTimeSurvey === "1" ? 2 : 3,
                    value: isOneTimeSurvey === "1" ? true : false,
                  },
                ]
              : []),
          ],
        };
        const dashboardDataReq = {
          ...data,
          userVisibility: 2,
        };

        dispatch(setLoading(true));
        await Promise.all([
          dispatch(GetSurveyDeliveredData(data)),
          dispatch(GetDashboardData(dashboardDataReq)),
        ]);
        dispatch(setLoading(false));
      }
    },
    [dispatch, isOneTimeSurvey]
  );

  const init = useCallback(async () => {
    const dates = [dayjs().subtract(3, "month"), dayjs()];
    reset({
      range: dates,
      isOneTimeSurvey: "0",
    });
    if (isOneTimeSurvey && dates[0]) {
      getFilteredDashboardData(dates);
    }
  }, [getFilteredDashboardData, isOneTimeSurvey, reset]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Math.floor(
  //   (deliveredData?.responded /
  //     deliveredData?.delivered) *
  //   100
  // )

  return (
    <Box>
      <Box>
        <FormProvider {...methods}>
          <Box
            display="flex"
            px={2}
            pt={2}
            alignItems={lgUp ? "center" : " left"}
            flexDirection={lgUp ? "row" : "column"}
          >
            <Box flex={3}>
              <Typography
                mb={1}
                variant="h4"
                fontWeight={500}
                color="text.secondary"
              >
                {t("overview_section_title")}
              </Typography>
            </Box>
            <Box display="flex" gap={2}>
              <Box>
                <Controller
                  name="isOneTimeSurvey"
                  defaultValue={"0"}
                  render={({ field }) => {
                    return (
                      <Box mt={0.5}>
                        <ToggleButtonGroup {...field}>
                          {quickFilterOneTimeTypesFeedback?.map(
                            (type, index) => {
                              return (
                                <StyledToggleButton
                                  size="small"
                                  key={index}
                                  value={type.value}
                                  selected={isOneTimeSurvey === type.value}
                                  onClick={(e) => {
                                    if (e.target.value === isOneTimeSurvey) {
                                      e.preventDefault();
                                    }
                                  }}
                                >
                                  {type.label}
                                </StyledToggleButton>
                              );
                            }
                          )}
                        </ToggleButtonGroup>
                      </Box>
                    );
                  }}
                />
              </Box>
              <Box flex={1}>
                <BasicRangePicker
                  name="range"
                  onSubmit={getFilteredDashboardData}
                />
              </Box>
            </Box>
          </Box>
          <Box mx={2} gap={4} display="flex" py={2} flexWrap={"wrap"}>
            <Box flex={1}>
              <Card sx={{ minHeight: 133 }}>
                <CardContent>
                  {deliveredData && (
                    <Box display="flex" justifyContent="space-around">
                      <Box display="flex">
                        <img src={CompletedRate} alt="rate" />
                        <Box ml={1}>
                          <Typography fontSize={14}>
                            {t("overview_surveys_completed")}
                          </Typography>
                          <Typography fontSize={32}>
                            {deliveredData?.responded}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex">
                        <img src={CompletedCount} alt="count" />
                        <Box ml={1}>
                          <Typography fontSize={14}>
                            {t("overview_response_rate")}
                          </Typography>
                          <Typography fontSize={32}>
                            {deliveredData?.opened &&
                              deliveredData?.responded &&
                              Math.floor(
                                (deliveredData?.responded /
                                  deliveredData?.delivered) *
                                  100
                              )}
                            %
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Box>
            <Box flex={1}>
              <Card sx={{ padding: "5px", minHeight: 133 }}>
                <CardContent>
                  {deliveredData && (
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Typography fontSize={14}>
                          {t("overview_sent")}
                        </Typography>
                        <Typography
                          fontSize={32}
                          sx={{ lineHeight: "38px", color: "#007AFF" }}
                        >
                          {deliveredData?.sent}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize={14}>
                          {t("overview_delivered")}
                        </Typography>
                        <Typography
                          fontSize={32}
                          sx={{ lineHeight: "38px", color: "#643DC7" }}
                        >
                          {deliveredData?.delivered}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize={14}>
                          {t("overview_opened")}
                        </Typography>
                        <Typography
                          fontSize={32}
                          sx={{ lineHeight: "38px", color: "#A13AC6" }}
                        >
                          {deliveredData?.opened}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography fontSize={14}>
                          {t("overview_bounced")}
                        </Typography>
                        <Typography
                          fontSize={32}
                          sx={{ lineHeight: "38px", color: "#DD3A97" }}
                        >
                          {deliveredData?.bounced}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Box>
          </Box>

          <Box>
            {dashboardData?.nps && (
              <ScoreCharts
                label={t("dashboard_nps_key")}
                dashboardDataChopChart={dashboardData?.npsChopChart}
                scoreData={dashboardData?.nps}
                type={ECampaignSurveyType.Nps}
                metricType={EDashboardExportMetricTypes.NPS}
                rangeDates={methods.watch("range")}
              />
            )}
            {dashboardData?.friendliness && (
              <ScoreCharts
                label={t("dashboard_friendliness_key")}
                dashboardDataChopChart={dashboardData?.friendlinessChopChart}
                scoreData={dashboardData?.friendliness}
                type={ECampaignSurveyType.ServiceQualityScore}
                metricType={EDashboardExportMetricTypes.eNPS}
                rangeDates={methods.watch("range")}
              />
            )}
            {dashboardData?.effortScore && (
              <ScoreCharts
                label={t("dashboard_effort_key")}
                dashboardDataChopChart={dashboardData?.effortScoreChopChart}
                scoreData={dashboardData?.effortScore}
                type={ECampaignSurveyType.CustomerEffortScore}
                metricType={EDashboardExportMetricTypes.CES}
                rangeDates={methods.watch("range")}
              />
            )}

            {dashboardData?.satisfactionScore && (
              <ScoreCharts
                label={t("dashboard_satisfaction_key")}
                dashboardDataChopChart={
                  dashboardData?.satisfactionScoreChopChart
                }
                scoreData={dashboardData?.satisfactionScore}
                type={ECampaignSurveyType.CustomerSatisfactionScore}
                metricType={EDashboardExportMetricTypes.CSAT}
                rangeDates={methods.watch("range")}
              />
            )}
            <TrendChart chartsData={dashboardData?.lineChartData} />
          </Box>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default Dashboard;
