import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import { injectStore } from "store/services/apiService";

const getUrlFromConfig = () => {
  let res = "";
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      res = JSON.parse(xhttp.responseText).api;

      if (res) {
        return res;
      } else {
        return `${window.location.host}/api`;
      }
    }
  };
  xhttp.open("GET", "/config.json", false);
  xhttp.send();
  return res;
};

injectStore(store);

let URL_PROJ = getUrlFromConfig();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const EBaseUrl = {
  API: `${URL_PROJ}/api`,
  MediaTemplateURL: `${URL_PROJ}/media/template`,
  BaseURL: URL_PROJ,
  MediaUserURL: `${URL_PROJ}/media/user`,
};

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
