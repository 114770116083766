import { Theme, useMediaQuery } from "@mui/material";
import { useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { IScoreValues } from "store/interfaces/dashboard";
import { selectLoadingState } from "store/slicers/common";

const PieChart = ({
  chartData,
  label,
  colors,
  labels,
  series,
}: {
  chartData: IScoreValues;
  label: string;
  colors: string[];
  labels: string[];
  series: number[];
}) => {
  const lgUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  const width = lgUp ? "400" : "240";

  const isLoading = useSelector(selectLoadingState);

  const formatterTotal = useCallback(
    (w: any) => {
      return `${chartData?.score}%`;
    },
    [chartData?.score]
  );

  return (
    <div>
      {!isLoading ? (
        <ReactApexChart
          options={{
            legend: {
              position: "bottom",
            },
            chart: {
              type: "pie",
              width,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: true,
                    },
                    value: {
                      show: true,
                    },
                    total: {
                      show: true,
                      label: label,
                      showAlways: true,
                      formatter: formatterTotal,
                    },
                  },
                },
              },
            },
            labels: labels,
            colors: colors,
          }}
          series={series}
          type="donut"
          width={width}
          height={lgUp ? "auto" : "300"}
        />
      ) : null}
    </div>
  );
};

export default PieChart;
