import { Box, Divider, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ButtonLoader from "shared/ui/ButtonLoader";
import BasicSelect from "shared/ui/Select";
import TextInput from "shared/ui/TextInput";

const PrioritySettings = () => {
  const methods = useForm({
    defaultValues: {
      generalSwitch: true,
      groupSwitch: true,
      surveySwitch: true,
    },
  });

  const [isFirstButtonDisabled, setFirstButtonDisabled] = useState(false);
  const [isSecondButtonDisabled, setSecondButtonDisabled] = useState(false);
  const [isThirdButtonDisabled, setThirdButtonDisabled] = useState(false);

  return (
    <FormProvider {...methods}>
      <Box p={4} sx={{ width: "70%" }}>
        <Typography variant="h5" mb={4}>
          General Quarantine Settings
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "50%",
          }}
        >
          <Box>
            <Typography variant="h6" mb={1}>
              Global Quarantine Limit
            </Typography>
            <Typography variant="subtitle2" color="primary.main">
              Configure global limits on the number of surveys sent within a
              specified time period.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography>Activate</Typography>
            <Controller
              name="generalSwitch"
              control={methods.control}
              defaultValue={true}
              render={({ field }) => (
                <Switch color="success" {...field} checked={field.value} />
              )}
            />
          </Box>
          <TextInput label="Max Surveys" name="max_surveys" />
          <BasicSelect
            options={[
              { id: 1, name: "Daily" },
              { id: 2, name: "Weekly" },
              { id: 3, name: "Monthly" },
            ]}
            label={"Time Period"}
            name={"time_period"}
            labelProp="name"
            valueProp="id"
            onChangeCB={() => {
              setFirstButtonDisabled(false);
            }}
          />
          <BasicSelect
            options={[
              { id: 1, name: "High" },
              { id: 2, name: "Medium" },
              { id: 3, name: "Low" },
            ]}
            label={"Priority"}
            name={"priority"}
            labelProp="name"
            valueProp="id"
            onChangeCB={() => {
              setFirstButtonDisabled(false);
            }}
          />
          <ButtonLoader
            isLoading={false}
            onClick={() => {
              setFirstButtonDisabled(true);
            }}
            disabled={isFirstButtonDisabled}
          >
            <Typography>Save</Typography>
          </ButtonLoader>
        </Box>
        <Divider sx={{ my: 4, borderColor: "primary.main", width: "50%" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "50%",
          }}
        >
          <Box>
            <Typography variant="h6" mb={1}>
              Group-Specific Quarantine Limits
            </Typography>
            <Typography variant="subtitle2" color="primary.main">
              Set unique limits for specific recipient groups
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography>Activate</Typography>
            <Controller
              name="groupSwitch"
              control={methods.control}
              defaultValue={true}
              render={({ field }) => (
                <Switch color="success" {...field} checked={field.value} />
              )}
            />
          </Box>

          <BasicSelect
            options={[
              { id: 1, name: "Premium client" },
              { id: 2, name: "Retail clients" },
            ]}
            label={"Groups"}
            name={"group"}
            labelProp="name"
            valueProp="id"
            onChangeCB={() => {
              setSecondButtonDisabled(false);
            }}
          />
          <TextInput label="Max Surveys" name="max_surveys_1" />
          <BasicSelect
            options={[
              { id: 1, name: "Daily" },
              { id: 2, name: "Weekly" },
              { id: 3, name: "Monthly" },
            ]}
            label={"Time Period"}
            name={"time_period_1"}
            labelProp="name"
            valueProp="id"
            onChangeCB={() => {
              setSecondButtonDisabled(false);
            }}
          />
          <BasicSelect
            options={[
              { id: 1, name: "High" },
              { id: 2, name: "Medium" },
              { id: 3, name: "Low" },
            ]}
            label={"Priority"}
            name={"priority_1"}
            labelProp="name"
            valueProp="id"
            onChangeCB={() => {
              setSecondButtonDisabled(false);
            }}
          />
          <ButtonLoader
            isLoading={false}
            onClick={() => {
              setSecondButtonDisabled(true);
            }}
            disabled={isSecondButtonDisabled}
          >
            <Typography>Save</Typography>
          </ButtonLoader>
        </Box>
        <Divider sx={{ my: 4, borderColor: "primary.main", width: "50%" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "50%",
          }}
        >
          <Box>
            <Typography variant="h6" mb={1}>
              Survey-Specific Quarantine Limits
            </Typography>
            <Typography variant="subtitle2" color="primary.main">
              Set unique limits for survey
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography>Activate</Typography>
            <Controller
              name="surveySwitch"
              control={methods.control}
              defaultValue={true}
              render={({ field }) => (
                <Switch color="success" {...field} checked={field.value} />
              )}
            />
          </Box>

          <BasicSelect
            options={[
              { id: 1, name: "2-way sms survey 2" },
              { id: 2, name: "2-way sms survey 1" },
              { id: 4, name: "2-way sms survey default" },
              { id: 3, name: "CX survey Test" },
              { id: 5, name: "CX survey" },
              { id: 6, name: "CX survey default" },
            ]}
            label={"Surveys"}
            name={"survey"}
            labelProp="name"
            valueProp="id"
            onChangeCB={() => {
              setThirdButtonDisabled(false);
            }}
          />
          <TextInput label="Number of transactions" name="transactions" />
          <BasicSelect
            options={[
              { id: 1, name: "Daily" },
              { id: 2, name: "Weekly" },
              { id: 3, name: "Monthly" },
            ]}
            label={"Time Period"}
            name={"time_period_2"}
            labelProp="name"
            valueProp="id"
            onChangeCB={() => {
              setThirdButtonDisabled(false);
            }}
          />
          <BasicSelect
            options={[
              { id: 1, name: "High" },
              { id: 2, name: "Medium" },
              { id: 3, name: "Low" },
            ]}
            label={"Priority"}
            name={"priority_2"}
            labelProp="name"
            valueProp="id"
            onChangeCB={() => {
              setThirdButtonDisabled(false);
            }}
          />
          <ButtonLoader
            isLoading={false}
            onClick={() => {
              setThirdButtonDisabled(true);
            }}
            disabled={isThirdButtonDisabled}
          >
            <Typography>Save</Typography>
          </ButtonLoader>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default PrioritySettings;
